import {fork} from "redux-saga/effects";
import {authSaga} from "../../auth/sagas";
import {
	runSagaForAcceptedTherapists,
	runSagaForClients,
	runSagaForTherapists,
	whenLoggedIn,
} from "../../auth/sagas/helper";
import chatSaga from "../../chat/sagas";
import contactSaga from "../../contacts/sagas";
import bootstrapApplication from "../../bootstrap/bootstrapSaga";
import sentrySaga from "../../errorHandler/sentrySaga";
import emdrSaga from "../../emdr/emdrSaga";
import connectionStatusSaga from "../../connectionStatus/sagas/connectionStatusSaga";
import {customTrackerSaga} from "../../tracker/customTracker/sagas";
import {inactiveClientSaga} from "../../inactiveClient/sagas";
import {userProfileSaga} from "../../userProfile/sagas";
import userSettingsSaga from "../../userSettings/userSettingsSaga";
import {clientNetworkSaga} from "../../clientNetwork/sagas";
import callSaga from "../../call/sagas";
import {organizationsSaga} from "../../organizations/sagas";
import {meetingNotesSaga} from "../../tracker/meetingNotes/sagas";
import treatmentStatusSaga from "../../treatmentStatus/sagas/treatmentStatusSaga";
import {clientActivitySaga} from "../../clientActivity/sagas/clientActivitySaga";
import createLogger from "../../logger/createLogger";
import {whenBootstrapped} from "../../bootstrap/sagas/whenBootstrappedSaga";
import {newFeaturePopupSaga} from "../../newFeaturePopup/newFeaturePopupSaga";
import emailVerificationSaga from "../../dashboard/sagas/emailVerificationSaga";
import {notificationSaga} from "../../notifications/sagas/notificationSaga";
import {versionCheck} from "../../versionCheck/sagas/";
import {clientsFeedSaga} from "../../clientsFeed/sagas";
import languagePreferenceSaga from "../../userProfile/sagas/languagePreferenceSaga";
import psychoEducationSaga from "../../tracker/psychoEducation/sagas";
import {NDBlogSearchSaga} from "../../nicedayBlogSearch/sagas";
import privateNotesSaga from "../../privateNotes/sagas/privateNotesSaga";
import planActivitySaga from "../../tracker/events/saga/planActivitySaga";
import timeTrackingSaga from "../../timeTracking/sagas/timeTrackingSaga";
import fileSharingSaga from "../../fileSharing/sagas/fileSharingSaga";
import twoFASaga from "../../twoFactorAuthentication/redux/twoFASaga";
import calendarSaga from "../../calendar/sagas/calendarSaga";
import featureFlagSaga from "../../featureFlags/FeatureFlagSaga";
import behaviorExprSaga from "../../tracker/behaviorExpr/saga/behaviorExprSaga";
import clientNoteSaga from "../../clientNotesPage/sagas/clientNoteSaga";
import interventionNoteSaga from "../../intervention/note/saga/interventionNoteSaga";
import therapySessionSaga from "../../tracker/therapySession/sagas/therapySessionSaga";
import interventionTreatmentGoalSaga from "../../intervention/treatmentGoal/saga/interventionTreatmentGoalSaga";
import diaryEntrySaga from "../../tracker/diaryEntry/sagas/diaryEntrySaga";
import interventionCustomTrackerSaga from "../../intervention/customTracker/saga/interventionCustomTrackerSaga";
import interventionPsychoEducationSaga from "../../intervention/psychoEducation/saga/interventionPsychoEducationSaga";
import interventionDiarySaga from "../../intervention/diary/saga/interventionDiarySaga";
import interventionBehaviorExperimentSaga from "../../intervention/behaviorExperiment/saga/interventionBehaviorExperimentSaga";
import interventionSessionSaga from "../../intervention/session/saga/interventionSessionSaga";
import checkInFeelingSaga from "../../tracker/checkInFeeling/sagas";
import thoughtRecordSaga from "../../tracker/thoughtRecord/sagas";
import clientManagementSaga from "../../caseload/sagas/clientManagementSaga";
import {userManagementSaga} from "../../userManagement/saga/userManagementSaga";
import interventionAssessmentSaga from "../../intervention/assessment/saga/interventionAssessmentSaga";
import assessmentSaga from "../../tracker/assessment/saga/assessmentSaga";
import {xaiSaga} from "../../xai/saga/xaiSaga";
import groupTherapySaga from "../../groupTherapy/saga/groupTherapySaga";
import taskRegistrationSaga from "../../taskRegistration/sagas/taskSagaRegistration";
import interventionTaskRegistrationSaga from "../../intervention/taskRegistration/saga/interventionTaskRegistrationSaga";
import presetSaga from "../../presets/sagas/presetSaga";
import inviteClientsSaga from "../../inviteClients/saga/inviteClientsSaga";
import interventionCustomExerciseSaga from "../../intervention/customExercise/saga/interventionCustomExerciseSaga";
import customExerciseSaga from "../../tracker/customExercise/sagas/customExerciseSaga";
import {watchBindActionToPromise} from "../../helpers/sagas/bindActionToPromise";
import romSaga from "../../rom/sagas/romSaga";
import startCallScreenSaga from "../../startCallScreen/sagas/startCallScreenSaga";
import agoraSaga from "../../agoraMeeting/saga/agoraSaga";
import referralClientSaga from "../../referralClient/sagas/referredClientSaga";

const logger = createLogger("rootSaga");

//
// Runs all static saga in the application
//
export default function* () {
	logger.addBreadcrumb({message: "Initializing root saga"});

	yield fork(bootstrapApplication);
	yield fork(sentrySaga);
	yield fork(connectionStatusSaga);
	yield fork(languagePreferenceSaga);
	yield fork(authSaga);
	yield fork(notificationSaga);
	yield fork(twoFASaga);
	yield fork(emailVerificationSaga);

	// Runs saga for logged in users
	yield fork(whenBootstrapped(whenLoggedIn(loggedInUserSaga)));
}

function* loggedInUserSaga() {
	// Runs saga for therapists user
	yield fork(runSagaForTherapists(therapistsUserSaga));
	// Runs saga for clients user
	yield fork(runSagaForClients(clientsUserSaga));

	// Run saga for users no matter what role they have
	yield fork(featureFlagSaga);
	yield fork(callSaga);
	yield fork(chatSaga);
	yield fork(contactSaga);
	yield fork(userProfileSaga);
	yield fork(userSettingsSaga);
	yield fork(newFeaturePopupSaga);
	yield fork(versionCheck);
	yield fork(fileSharingSaga);
	yield fork(watchBindActionToPromise);
	yield fork(startCallScreenSaga);
	yield fork(agoraSaga);
	yield fork(referralClientSaga);
}

function* therapistsUserSaga() {
	// Add saga that should be run only when logged in user is a therapist
	yield fork(calendarSaga);
	yield fork(clientActivitySaga);
	yield fork(clientNetworkSaga);
	yield fork(customTrackerSaga);
	yield fork(emdrSaga);
	yield fork(inactiveClientSaga);
	yield fork(meetingNotesSaga);
	yield fork(organizationsSaga);
	yield fork(runSagaForAcceptedTherapists(treatmentStatusSaga));
	yield fork(clientsFeedSaga);
	yield fork(NDBlogSearchSaga);
	yield fork(privateNotesSaga);
	yield fork(runSagaForAcceptedTherapists(timeTrackingSaga));
	yield fork(psychoEducationSaga);
	yield fork(planActivitySaga);
	yield fork(behaviorExprSaga);
	yield fork(clientNoteSaga);
	yield fork(interventionNoteSaga);
	yield fork(therapySessionSaga);
	yield fork(interventionTreatmentGoalSaga);
	yield fork(interventionCustomTrackerSaga);
	yield fork(diaryEntrySaga);
	yield fork(interventionPsychoEducationSaga);
	yield fork(interventionDiarySaga);
	yield fork(interventionBehaviorExperimentSaga);
	yield fork(interventionSessionSaga);
	yield fork(checkInFeelingSaga);
	yield fork(thoughtRecordSaga);
	yield fork(clientManagementSaga);
	yield fork(assessmentSaga);
	yield fork(interventionAssessmentSaga);
	yield fork(xaiSaga);
	yield fork(groupTherapySaga);
	yield fork(taskRegistrationSaga);
	yield fork(interventionTaskRegistrationSaga);
	yield fork(presetSaga);
	yield fork(inviteClientsSaga);
	yield fork(interventionCustomExerciseSaga);
	yield fork(customExerciseSaga);
	yield fork(romSaga);

	/** TODO: @agung Move to `adminUserSaga` */
	yield fork(userManagementSaga);
}

function clientsUserSaga() {
	// Add saga that should be run only when logged in user is a client
}

import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {
	IReferralQuestionnaireQueryOptions,
	IReferralQuestionnairesResponse,
} from "@sense-os/goalie-js/dist/referralClient/type";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";

const log = createLogger("fetchReferralClient", SentryTags.ReferralClient);

export function* fetchReferredClient(action: ActionType<typeof referralClientActions.fetchReferralClientList.request>) {
	const {page, pageSize, status} = action.payload;
	const token = yield call(getSessionId);

	const options: IReferralQuestionnaireQueryOptions = {
		page,
		pageSize,
		status,
	};
	try {
		const response: IReferralQuestionnairesResponse = yield apiCallSaga(
			ReferralClientSDK.getReferralQuestionnaires,
			token,
			options,
		);
		yield put(
			referralClientActions.fetchReferralClientList.success({
				data: response.results,
				total: response.count,
				statusCount: response.statusCount,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.fetchReferralClientList.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.fetchReferralClientList.request), fetchReferredClient);
}

import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {toastActions} from "../../toaster/redux";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

const log = createLogger("createReferralClient", SentryTags.ReferralClient);

export function* createReferralClient(action: ActionType<typeof referralClientActions.createReferralClient.request>) {
	const {email, firstName, lastName, referralCode, language} = action.payload;
	const token = yield call(getSessionId);

	try {
		yield apiCallSaga(
			ReferralClientSDK.createReferralQuestionnaire,
			token,
			email,
			firstName,
			lastName,
			referralCode,
			language,
		);

		yield put(referralClientActions.createReferralClient.success());

		// TODO: put translation here
		yield put(toastActions.addToast({message: "Questionnaire is created", type: "info"}));
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.createReferralClient.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.createReferralClient.request), createReferralClient);
}

import {fork} from "redux-saga/effects";
import fetchReferralClients from "./fetchReferralClients";
import fetchReferralClientDetail from "./fetchReferralClientDetail";
import createReferralClient from "./createReferralClient";
import createReferralReview from "./createReferralReview";
import updateReferralClientStatus from "./updateReferralClientStatus";
import updateReferralNotes from "./updateReferralNotes";
import fetchReferralTherapyType from "./fetchReferralTherapyType";

export default function* () {
	yield fork(fetchReferralClients);
	yield fork(fetchReferralClientDetail);
	yield fork(createReferralClient);
	yield fork(createReferralReview);
	yield fork(updateReferralClientStatus);
	yield fork(updateReferralNotes);
	yield fork(fetchReferralTherapyType);
}
